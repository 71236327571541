import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import './Navbar.css';
import { Link as ScrollLink } from 'react-scroll';

const Navbar = () => {

  return (
    <nav className="navbar">
      <Link to="/home">
        <img src="/images/logo.jpg" className="logo" alt="Logo" />
      </Link>
      <div className="nav-links">
        <Link to="/home" className="nav-link">
          Home
        </Link>
        <ScrollLink to="contact" smooth duration={1000}>
          <div className="nav-link">Contact</div>
        </ScrollLink>
      </div>
    </nav>
  );
};

export default Navbar;
