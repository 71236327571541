// src/components/Contact.js

import React from 'react';
import { FaEnvelope, FaLinkedin } from 'react-icons/fa';
import Navbar from './Navbar'; // Import Navbar component
import './Contact.css'; // Import the Contact-specific stylesheet
import Footer from './Footer';

const Contact = () => {
  const handleEmailClick = () => {
    window.location.href = 'mailto:kelshairaa@gmail.com';
  };

  const handleLinkedInClick = () => {
    window.open('https://www.linkedin.com/in/kelshaairameylie/', '_blank');
  };

  return (
    <div className="contact">
      <Navbar />
      <h2 className='contacts'>My Contacts</h2>
      <div className="social">
        <ul className="social-set">
          {/* Email */}
          <li>
            <a className="sociali" onClick={handleEmailClick} aria-label="Email">
              <FaEnvelope className="fa-fw" title="Email" />
            </a>
          </li>

          {/* LinkedIn */}
          <li>
            <a className="sociali" onClick={handleLinkedInClick} aria-label="LinkedIn">
              <FaLinkedin className="fa-fw" title="LinkedIn" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Contact;
