import React, { useEffect, useState } from 'react';
import { useSpring, animated, config } from 'react-spring';
import Navbar from './Navbar';
import './Home.css';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import { FaEnvelope, FaLinkedin } from 'react-icons/fa';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';

const Home = () => {
  const [hiText, setHiText] = useState('');
  const [nameText, setNameText] = useState('');
  const [isNameAnimationComplete, setIsNameAnimationComplete] = useState(false);
  const [isPhotoVisible, setIsPhotoVisible] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [isGetInTouchAnimationComplete, setIsGetInTouchAnimationComplete] = useState(false);


  useEffect(() => {
    const hiTargetText = "Hi, It's";
    let hiIndex = 0;

    const hiIntervalId = setInterval(() => {
      setHiText(hiTargetText.substring(0, hiIndex));
      hiIndex++;

      if (hiIndex > hiTargetText.length) {
        clearInterval(hiIntervalId);
      }
    }, 100);

    return () => {
      clearInterval(hiIntervalId);
    };
  }, []);

  useEffect(() => {
    const hiTargetText = "Hi, It's";
    let hiIndex = 0;

    const hiIntervalId = setInterval(() => {
      setHiText(hiTargetText.substring(0, hiIndex));
      hiIndex++;

      if (hiIndex > hiTargetText.length) {
        clearInterval(hiIntervalId);

        const nameTargetText = "Kelsha Aira Meylie";
        let nameIndex = 0;

        const nameIntervalId = setInterval(() => {
          setNameText(nameTargetText.substring(0, nameIndex));
          nameIndex++;

          if (nameIndex > nameTargetText.length) {
            clearInterval(nameIntervalId);
            setIsNameAnimationComplete(true);

            setTimeout(() => {
              setIsPhotoVisible(true);
            }, 300);
          }
        }, 100);
      }
    }, 100);

    return () => {
      clearInterval(hiIntervalId);
    };
  }, []);

  useEffect(() => {
    if (isPhotoVisible) {
      setTimeout(() => {
        setIsButtonVisible(true);
      }, 375);
    }
  }, [isPhotoVisible]);

  const nameSpring = useSpring({
    opacity: isNameAnimationComplete ? 1 : 0,
    transform: isNameAnimationComplete ? 'translateY(0)' : 'translateY(20px)',
    config: config.gentle,
  });

  const photoSpring = useSpring({
    opacity: isPhotoVisible ? 1 : 0,
    transform: isPhotoVisible ? 'translateY(0) scale(1)' : 'translateY(2px) scale(0.99)',
    from: { opacity: 0, transform: 'translateY(2px) scale(0.99)' },
    config: { tension: 100, friction: 8 },
  });

  const revealSpring = useSpring({
    opacity: isNameAnimationComplete ? 1 : 0,
    transform: isNameAnimationComplete ? 'translateY(0)' : 'translateY(20px)',
    config: config.gentle,
  });

  const buttonSpring = useSpring({
    opacity: isButtonVisible ? 1 : 0,
    transform: isButtonVisible ? 'translateY(0)' : 'translateY(20px)',
    config: config.gentle,
    onRest: () => {
      setIsGetInTouchAnimationComplete(true);
    },
  });  

  const leftToRightSpring = useSpring({
    opacity: isNameAnimationComplete ? 1 : 0,
    transform: isNameAnimationComplete ? 'translateX(0)' : 'translateX(-20px)',
    config: config.gentle,
  });

  const [isPhotoFlipped, setIsPhotoFlipped] = useState(false);

  const handlePhotoClick = () => {
    setIsPhotoFlipped(!isPhotoFlipped);
  };

  const photoFlipSpring = useSpring({
    transform: isPhotoFlipped ? 'scaleX(-1)' : 'scaleX(1)',
    config: { duration: 400 },
  });

  const handleEmailClick = () => {
    window.location.href = 'mailto:kelshairaa@gmail.com';
  };

  const handleLinkedInClick = () => {
    window.open('https://www.linkedin.com/in/kelshaairameylie/', '_blank');
  };

  const aboutMeSpring = useSpring({
    opacity: isGetInTouchAnimationComplete ? 1 : 0,
    transform: isGetInTouchAnimationComplete ? 'translateY(0)' : 'translateY(20px)',
    config: config.gentle,
  });
  
  
  
  return (
    <div className="home">
      <Navbar />

      <div className="centered-content">
        <div className="card">
          <div className="card-header">
            <div className="header-content">
              <h2>{hiText}</h2>
              <h1>{nameText}</h1>
              <animated.h3 style={{ ...revealSpring, ...leftToRightSpring }}>
                Website and Mobile App Developer.
              </animated.h3>
              <ScrollLink to="contact" smooth duration={1200}>
                <animated.button style={buttonSpring} className="cta-button">
                  Get in Touch
                </animated.button>
              </ScrollLink>
            </div>
            <div className="custom-shaped-foto-container">
            <animated.img
                style={{ ...photoSpring, ...photoFlipSpring }}
                src={isPhotoFlipped ? '/images/imgclick.jpg' : '/images/foto.jpg'}
                className="custom-shaped-foto"
                alt="me"
                onClick={handlePhotoClick}
            />
            </div>
          </div>
        </div>
      </div>
      <section className="about-me" id="aboutme">
        <animated.h2 style={aboutMeSpring} className="aboutme-header">
          About Me
        </animated.h2>
        <animated.p style={aboutMeSpring} className="aboutme-content">
          Hello! I'm Kelsha Aira Meylie, a computer science undergraduate student at Multimedia Nusantara University. I'm a passionate website and mobile app developer based in Jakarta, Indonesia. With a strong foundation in front-end and back-end technologies, I love transforming ideas into interactive and user-friendly digital experiences. My journey in the world of development has been exciting, and I'm always eager to learn and explore new technologies to enhance my skills further.
        </animated.p>
      </section>
      <section class="skills" id="skills">
      <h2 class="skill-header">Skills</h2>

      <div class="skills-wrapper">
        <div class="first-set animate__animated animate__pulse">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48" class="icon icon-card">
            <path fill="#E65100" d="M41,5H7l3,34l14,4l14-4L41,5L41,5z"></path>
            <path fill="#FF6D00" d="M24 8L24 39.9 35.2 36.7 37.7 8z"></path>
            <path fill="#FFF" d="M24,25v-4h8.6l-0.7,11.5L24,35.1v-4.2l4.1-1.4l0.3-4.5H24z M32.9,17l0.3-4H24v4H32.9z"></path>
            <path fill="#EEE" d="M24,30.9v4.2l-7.9-2.6L15.7,27h4l0.2,2.5L24,30.9z M19.1,17H24v-4h-9.1l0.7,12H24v-4h-4.6L19.1,17z"></path>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48" class="icon icon-card">
            <path fill="#0277BD" d="M41,5H7l3,34l14,4l14-4L41,5L41,5z"></path>
            <path fill="#039BE5" d="M24 8L24 39.9 35.2 36.7 37.7 8z"></path>
            <path fill="#FFF" d="M33.1 13L24 13 24 17 28.9 17 28.6 21 24 21 24 25 28.4 25 28.1 29.5 24 30.9 24 35.1 31.9 32.5 32.6 21 32.6 21z"></path><path fill="#EEE" d="M24,13v4h-8.9l-0.3-4H24z M19.4,21l0.2,4H24v-4H19.4z M19.8,27h-4l0.3,5.5l7.9,2.6v-4.2l-4.1-1.4L19.8,27z"></path>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48" class="icon icon-card">
            <path fill="#ffd600" d="M6,42V6h36v36H6z"></path>
            <path fill="#000001" d="M29.538 32.947c.692 1.124 1.444 2.201 3.037 2.201 1.338 0 2.04-.665 2.04-1.585 0-1.101-.726-1.492-2.198-2.133l-.807-.344c-2.329-.988-3.878-2.226-3.878-4.841 0-2.41 1.845-4.244 4.728-4.244 2.053 0 3.528.711 4.592 2.573l-2.514 1.607c-.553-.988-1.151-1.377-2.078-1.377-.946 0-1.545.597-1.545 1.377 0 .964.6 1.354 1.985 1.951l.807.344C36.452 29.645 38 30.839 38 33.523 38 36.415 35.716 38 32.65 38c-2.999 0-4.702-1.505-5.65-3.368L29.538 32.947zM17.952 33.029c.506.906 1.275 1.603 2.381 1.603 1.058 0 1.667-.418 1.667-2.043V22h3.333v11.101c0 3.367-1.953 4.899-4.805 4.899-2.577 0-4.437-1.746-5.195-3.368L17.952 33.029z"></path>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48" class="icon icon-card">
          <path fill="#80deea" d="M24,34C11.1,34,1,29.6,1,24c0-5.6,10.1-10,23-10c12.9,0,23,4.4,23,10C47,29.6,36.9,34,24,34z M24,16	c-12.6,0-21,4.1-21,8c0,3.9,8.4,8,21,8s21-4.1,21-8C45,20.1,36.6,16,24,16z"></path>
          <path fill="#80deea" d="M15.1,44.6c-1,0-1.8-0.2-2.6-0.7C7.6,41.1,8.9,30.2,15.3,19l0,0c3-5.2,6.7-9.6,10.3-12.4c3.9-3,7.4-3.9,9.8-2.5	c2.5,1.4,3.4,4.9,2.8,9.8c-0.6,4.6-2.6,10-5.6,15.2c-3,5.2-6.7,9.6-10.3,12.4C19.7,43.5,17.2,44.6,15.1,44.6z M32.9,5.4	c-1.6,0-3.7,0.9-6,2.7c-3.4,2.7-6.9,6.9-9.8,11.9l0,0c-6.3,10.9-6.9,20.3-3.6,22.2c1.7,1,4.5,0.1,7.6-2.3c3.4-2.7,6.9-6.9,9.8-11.9	c2.9-5,4.8-10.1,5.4-14.4c0.5-4-0.1-6.8-1.8-7.8C34,5.6,33.5,5.4,32.9,5.4z"></path>
          <path fill="#80deea" d="M33,44.6c-5,0-12.2-6.1-17.6-15.6C8.9,17.8,7.6,6.9,12.5,4.1l0,0C17.4,1.3,26.2,7.8,32.7,19	c3,5.2,5,10.6,5.6,15.2c0.7,4.9-0.3,8.3-2.8,9.8C34.7,44.4,33.9,44.6,33,44.6z M13.5,5.8c-3.3,1.9-2.7,11.3,3.6,22.2	c6.3,10.9,14.1,16.1,17.4,14.2c1.7-1,2.3-3.8,1.8-7.8c-0.6-4.3-2.5-9.4-5.4-14.4C24.6,9.1,16.8,3.9,13.5,5.8L13.5,5.8z"></path><circle cx="24" cy="24" r="4" fill="#80deea"></circle>
        </svg>
        </div>

        <div class="second-set animate__animated animate__pulse">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48" class="icon icon-card">
          <circle cx="24" cy="24" r="9" fill="#448aff"></circle>
          <circle cx="37" cy="11" r="4" fill="#448aff"></circle>
          <path fill="#448aff" d="M41.706,14.715c-0.842,1.064-2.034,1.836-3.405,2.139C39.379,19.007,40,21.429,40,24	c0,8.837-7.163,16-16,16S8,32.837,8,24S15.163,8,24,8c2.571,0,4.993,0.621,7.145,1.699c0.303-1.371,1.075-2.564,2.139-3.405	C30.509,4.835,27.354,4,24,4C12.954,4,4,12.954,4,24c0,11.046,8.954,20,20,20s20-8.954,20-20C44,20.646,43.165,17.491,41.706,14.715	z"></path>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48" class="icon icon-card">
          <polygon fill="#0d91d6" points="24,6 17.99,12.35 11.97,18.69 6,25 6,6"></polygon>
          <polygon fill="#f88909" points="24,6 23.96,6 17.97,12.33 11.96,18.68 6,24.97 6,25 6,25.09 6,42 14.69,33.31 20.87,27.13 27.06,20.94 33.25,14.75 42,6"></polygon>
          <polygon fill="#c757bc" points="14.69,33.31 6,42 6,25.09"></polygon><polygon fill="#d3638f" points="20.87,27.13 14.69,33.31 6,25.09 6,25 11.97,18.69"></polygon><polygon fill="#e07063" points="27.06,20.94 20.87,27.13 11.97,18.69 17.99,12.35"></polygon><polygon fill="#ec7d36" points="33.25,14.75 27.06,20.94 17.99,12.35 24,6"></polygon><polygon fill="#f88909" points="42,6 33.25,14.75 24,6"></polygon><polygon fill="#0095d5" points="11.51,19.15 7.99,22.88 6,21 6,13.94"></polygon><polygon fill="#2b88da" points="15.03,15.42 11.51,19.15 6,13.94 6,6.87"></polygon><polygon fill="#557bde" points="18.56,11.7 15.03,15.42 6,6.87 6,6 12.53,6"></polygon><polygon fill="#806ee3" points="22.08,7.97 18.56,11.7 12.53,6 24,6"></polygon><polygon fill="#0095d5" points="23,25 6,42 9.35,42 17.52,42 40,42"></polygon><polygon fill="#2b88da" points="25.68,42 17.52,42 11.76,36.25 15.84,32.17"></polygon><polygon fill="#557bde" points="33.86,42 25.68,42 15.84,32.17 19.92,28.09"></polygon><polygon fill="#806ee3" points="42,42 33.86,42 19.92,28.09 24,24"></polygon>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48" class="icon icon-card">
          <path fill="#ffa000" d="M25.01,8.565c-0.386-0.753-1.466-0.755-1.848,0l-2.347,4.426L15.404,2.547 c-0.471-0.874-1.798-0.653-1.952,0.325L8.003,37.997L30.25,18.75L25.01,8.565z"></path>
          <path fill="#f57f17" d="M25.795 22.604L20.815 12.992 8.003 37.997z"></path>
          <path fill="#ffca28" d="M35.859,11.838c-0.13-0.802-1.115-1.12-1.69-0.544L8.003,38.002l14.479,7.614 c0.917,0.512,2.034,0.512,2.951,0.001L40,38.005L35.859,11.838z"></path>
        </svg>
        </div>
      </div>
    </section>
    <div className="divider"></div>
    <section className="projects" id="projects">
  <h2 className="projects-header">Projects</h2>
  <div className="projects-content">
    <div className="project">
      <div className="project-number-large">1.</div>
      <div className="project-details">
        <div className="project-title">
          <h3>To-do List Application</h3>
        </div>
        <div className="project-description">
          <p>This is a final-term exam project for a Mobile Cross-Platform Programming course. In this project, I contributed as a Full-Stack Developer. I built with Ionic React. I was responsible for designing the entire user interface and navigating it. I also implemented and managed the database infrastructure using Firebase.</p>
        </div>
      </div>
    </div>

    <div className="project">
      <div className="project-number-large">2.</div>
      <div className="project-details">
        <div className="project-title">
          <h3>To-do List Application</h3>
        </div>
        <div className="project-description">
          <p>This is a final-term project for a Mobile Application Programming course. In this project, I contributed as a Full-Stack Developer. I built with Kotlin and Jetpack Compose toolkit. I was responsible for making the entire user interface (screens) and navigating it. Additionally, I also implemented and managed the database infrastructure using Firebase.</p>
        </div>
      </div>
    </div>

    <div className="project">
      <div className="project-number-large">3.</div>
      <div className="project-details">
        <div className="project-title">
          <h3>Flower Store Website</h3>
        </div>
        <div className="project-description">
          <p>This is a final-term project for the Object Oriented Programming course. In this project, I contributed as a Front-End Developer. I built with ReactJS. I was responsible for making the user interface and keeping the website user-friendly.</p>
        </div>
      </div>
    </div>

    <div className="project">
      <div className="project-number-large">4.</div>
      <div className="project-details">
        <div className="project-title">
          <h3>Game Website</h3>
        </div>
        <div className="project-description">
          <p>This is a final-term project for the Introduction of Internet course. In this project, I contributed as a Front-End Developer. I built with ReactJS. I was responsible for making the Figma and user interface. Also, I keep the website user-friendly and responsive.</p>
        </div>
      </div>
    </div>
    <div className="projectbottom"/>
  </div>
</section>
<section className="contact" id="contact">
<div className="contact">
        <h2 className='contacts'>My Contacts</h2>
        <div className="social">
          <ul className="social-set">
            {/* Email */}
            <li>
              <a className="sociali" onClick={handleEmailClick} aria-label="Email">
                <FaEnvelope className="fa-fw" title="Email" />
              </a>
            </li>

            {/* LinkedIn */}
            <li>
              <a className="sociali" onClick={handleLinkedInClick} aria-label="LinkedIn">
                <FaLinkedin className="fa-fw" title="LinkedIn" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
<Footer/>
</div>
  );
};

export default Home;